<template>
<v-container>
  <h2>Branch Details</h2>
  <div v-if="loading"  class="text-center">
    
    <v-row>
      <v-col cols="6">
          <v-progress-linear indeterminate color="blue"></v-progress-linear>
        </v-col>
      </v-row>
  </div>
  <div v-else>
    <div v-if="error">
      <v-row>
        <v-col cols="12" md="6">
          <v-alert type="warning">
          {{error_message}}
          </v-alert>
      </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols=12 md=12>
          <h4>Name</h4>
          <v-card outlined >
            <v-simple-table >
              <template v-slot:default>
                <tbody class="side_titles">
                  <tr>
                    <th >English Branch Name</th>
                    <td>{{online_data.info.name_en}}</td>
                  </tr>
                  <tr>
                    <th>Arabic Branch Name</th>
                    <td>{{online_data.info.name_ar}}</td>
                  </tr>
                  <tr v-if="online_data.cr.name_en">
                    <th>English Orgnization Name</th>
                    <td>{{online_data.cr.name_en}}</td>
                  </tr>
                  <tr v-if="online_data.cr.name_ar">
                    <th>Arabic Orgnization Name</th>
                    <td>{{online_data.cr.name_ar}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=6>
          <v-card outlined >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="side_titles">
                  <tr>
                    <th>CR-Branch Number</th>
                    <td>{{online_data.info.cr_branch_number}}</td>
                  </tr>
                  <tr>
                    <th>Company type</th>
                    <td>{{online_data.info.shop_cr_type}}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{{online_data.info.status}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols=12 md=6>
          <v-card outlined >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="side_titles">
                  <tr>
                    <th>Registeration Date</th>
                    <td>{{online_data.info.shop_registration_date}}</td>
                  </tr>
                  <tr>
                    <th>Expire Date</th>
                    <td>{{online_data.info.shop_expiry_date}}</td>
                  </tr>
                  <tr>
                    <th>Company Age</th>
                    <td>{{company_age}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=6>
          <v-card outlined v-if="show_address">
            <v-simple-table>
              <template v-slot:default>
                <tbody class="side_titles">
                  <tr>
                    <th>Shop number</th>
                    <td>{{online_data.address.shop_flat_shop_no}}</td>
                  </tr>
                  <tr>
                    <th>Building</th>
                    <td>{{online_data.address.shop_building}}</td>
                  </tr>
                  <tr>
                    <th>Road</th>
                    <td>{{online_data.address.shop_road}}</td>
                  </tr>
                  <tr>
                    <th>Block</th>
                    <td>{{online_data.address.shop_block}}</td>
                  </tr>
                  <tr>
                    <th>Town</th>
                    <td>{{online_data.address.shop_town}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          
          <v-card outlined class="mt-6" v-if="show_contact_info">
            <v-simple-table>
              <template v-slot:default>
                <tbody class="side_titles">
                  <tr>
                    <th>Phone 1</th>
                    <td>{{online_data.contact_info.shop_owner_mobile_number}}</td>
                  </tr>
                  <tr>
                    <th>Phone 2</th>
                    <td>{{online_data.contact_info.shop_phone_number}}</td>
                  </tr>
                  <tr>
                    <th>Fax</th>
                    <td>{{online_data.contact_info.shop_fax_number}}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{{online_data.contact_info.shop_email}}</td>
                  </tr>
                  <tr>
                    <th>Website</th>
                    <td>{{online_data.contact_info.shop_website}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols=12 md=6>
          <v-card outlined v-show="show_map" :loading="map_loading">
            <div class="map" ref='map' ></div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="online_data.capital">
        <v-col cols=12 md=6>
          <v-card outlined >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="side_titles">
                  <tr>
                    <th>Total Investment</th>
                    <td>{{online_data.capital.total_investment_base}} {{online_data.capital.base_currency}}</td>
                  </tr>
                  <tr>
                    <th>Number of Shares</th>
                    <td>{{online_data.capital.share_nominal_value}}</td>
                  </tr>
                  <tr>
                    <th>Share Value</th>
                    <td>{{online_data.capital.total_number_of_share}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <h4>Business Activities</h4>
          <v-card outlined >
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(activity,i) in online_data.activity" :key="i">
                <v-expansion-panel-header>
                  {{activity.name_en}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span style="white-space: pre-wrap">
                  {{activity.activity_description.trim()}}
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <h4>Branch History</h4>
          <v-card outlined >
            <v-simple-table>
              <template v-slot:default>
                <tbody >
                  <tr>
                    <th class="table_header_width">Date</th>
                    <th>Action</th>
                  </tr>
                  <tr v-for="(branch,i) in online_data.history" :key="i">
                    <td>{{branch.amendment_date}}</td>
                    <td>{{branch.application_type}}</td>
                    
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <h4>Other Branches</h4>
          <v-card outlined >
            <v-simple-table>
              <template v-slot:default>
                <tbody >
                  <tr>
                    <th class="table_header_width">CR-Branch Number</th>
                    <th>Name</th>
                    <th>Status</th>
                  </tr>
                  <tr v-for="(branch,i) in online_data.branches" :key="i">
                    <td> 
                      <router-link :to="{path: '/branch/'+branch.object_id}">
                        {{branch.cr_branch_number}}
                      </router-link>
                    </td>
                    <td> {{branch.name_en}} </td>
                    <td> {{branch.status}} </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</v-container>
</template>


<script>
// @ is an alias to /src
import {get_branch_details} from '@/api/app'
//import business_json from '@/data/business_activity_list.json'
export default {
  name: 'BranchDetails',
  data:function(){
    return {
      map_loading:false,
      show_address:false,
      show_contact_info:false,
      show_map:false,
      error:false,
      error_message:'',
      chart_colors:['#2d3436', '#636e72','#b2bec3','#00cec9'],
      capital_history_chart_data:{'2017-01-01': 5000, '2018-01-01': 2000, '2019-05-01': 9700},
      text:"Name",
      loading:false,
      online_data:{
         info: {
            cr_number: 0,
            cr_branch_number: "",
            name_ar: "",
            name_en: "",
            shop_country: "",
            shop_cr_type: "",
            status: "",
            shop_registration_date: "",
            shop_expiry_date: "",
            is_sijilli: false,
            cr_object_id: "",
            object_id: "",
            branch_number: 1,
            coordinate: [null, null],
        },
        activity:[],
        contact_info: {
            branch_number: 1,
            branch_object_id: "",
            cr_branch_number: "",
            cr_number: 0,
            cr_object_id: "",
            object_id: "",
            shop_email: "",
            shop_fax_number: null,
            shop_owner_mobile_number: "",
            shop_phone_number: null,
            shop_website: null
        },
        branches: [
            {
                cr_number: 0,
                cr_branch_number: "",
                name_ar: "",
                name_en: "",
                status: "",
                shop_registration_date: "",
                shop_expiry_date: "",
                cr_object_id: "",
                coordinate: [
                    0,
                    0
                ]
            }
        ],
        cr: {
            cr_number: 0,
            object_id: ""
        },
         capital: {
            cr_number: 0,
            cr_object_id: "",
            object_id: "",
            share_nominal_value: "0",
            total_number_of_share: "0"
        },
         address: {
            incubator_info: null,
            is_incubator_address: false,
            lat: null,
            lon: null,
            object_id: "",
            shop_block: "",
            shop_building: "",
            shop_flat_shop_no: "",
            shop_road: "",
            shop_town: ""
        },
      },
      //business_activity_list:business_json,
    
   }
 },
 computed: {
  company_age:function(){

    // check if status ACTIVE or Not
    let today = new Date().toJSON().slice(0,10)
    if (this.online_data.info.status!="ACTIVE" && this.online_data.info.status!="ACTIVE WITHOUT LICENSE"){
      today=this.online_data.info.shop_expiry_date;
    }
    let year_diff=today.slice(0,4)-this.online_data.info.shop_registration_date.slice(0,4)
    console.log("Month:",this.online_data.info.shop_registration_date.slice(5,7))
    let months_diff=today.slice(5,7)-this.online_data.info.shop_registration_date.slice(5,7)
    if( months_diff <0){
      months_diff=months_diff+12
    }
    console.log("year_diff: ",year_diff);
    
    let months_str="Months";
    if (months_diff ==1){
      months_str="Month";
    }
    let year_str="Years";
    if (year_diff==1){
      year_str="Year"
    }
    return year_diff+" "+year_str+" ," +months_diff+" "+months_str;
  }
 }
 ,
 methods:{
  fill_data: async function() {
    if (this.online_data.contact_info !=null){
      this.show_contact_info=true;
    }else{
      this.show_contact_info=false;
    }

    if (this.online_data.address !=null){
      this.show_address=true;
      this.map_loading=true;
      if (this.online_data.address.lat != null && this.online_data.address.lon != null){
         let {
          Maps
        } = await this.getMap()
        var navigationBarMode = Maps.NavigationBarMode;

        //Maps.Location(26.208386, 50.538245)
        let map =new Maps.Map(this.$refs.map, {
          navigationBarMode: navigationBarMode.square,
          mapTypeId: Maps.MapTypeId.canvasLight,
          center: new Maps.Location(this.online_data.address.lat,this.online_data.address.lon),
          supportedMapTypes: [Maps.MapTypeId.road, Maps.MapTypeId.aerial, Maps.MapTypeId.grayscale,Maps.MapTypeId.canvasLight]
        })
        //console.log('map: ',map)
        // //https://www.bing.com/api/maps/sdk/mapcontrol/isdk/adddefaultpushpin#TS
        let pin_location=new Maps.Location(this.online_data.address.lat,this.online_data.address.lon);
        var pushpin = new Maps.Pushpin(pin_location, null);

        map.entities.push(pushpin);
        this.map_loading=false;
        this.show_map=true;
      }else{
        this.show_map=false;
      }
    }else{
      // set values to null
      this.online_data.address={"lat":null,"lon":null};
      this.show_address=false;
    }
  },
  get_data:function(){

    this.loading=true;
    // update data
    let object_id =this.$route.params.objectId;
    const data_to_send={
          'object_id':object_id
        }

    get_branch_details(data_to_send).then((response)=>{
          
         
          if (response.error ==false){
             this.online_data=response.data;
          //this.chart_data=result_data;
          console.log('online data',this.online_data);
            this.fill_data()
          }else{
            // show error 
            this.error=true;
            this.error_message=response.error_message;
          }

          this.loading=false;

        })
  },
   getMap() {
      let script = document.createElement('script')
      script.src = 'https://www.bing.com/api/maps/mapcontrol?key=AgzeobkGvmpdZTFuGa7_6gkaHH7CXHKsFiTQlBvi55x-QLZLh1rSjhd1Da9bfPhD&callback=getMap';
      document.head.append(script)
      return new Promise(resolve => {
        window.getMap = function() {
          resolve(window.Microsoft)
        }
      })
    },
  },

   async mounted () {
    //console.log(this.$route.params.objectId);
   
    

    this.get_data();

    }
}
</script>

<style scoped>
.side_titles tr th{
  width: 180px;
}
.table_header_width{
  width: 180px; 
}

.map{
  width:100%;
  height: 505px;
}
@media only screen and (max-width: 768px) {
  .map{
    height: 350px;
  }
}

</style>
